.page-container.no-page {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  
    .page-link {
      color: $primary;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
      position: relative;
  
      .arrow {
        display: inline-block;
        width: 8px;
        height: 8px;
        top: 35%;
        transform: translateY(-50%) rotate(45deg);
        margin-left: 15px;
        border-top: 2px solid $primary;
        border-right: 2px solid $primary;
        position: absolute;
      }
    }
  }