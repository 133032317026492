@import "./preload-connector";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  a {
    color: $link-color;
    cursor: pointer;
    transition: 100ms ease all;
    text-decoration: none;
    &:hover {
      color: $link-color-hover;
      text-decoration: none;
    }
  }
}

:root {
  font-family: "Hanken Grotesk", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: $primary-bg;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }
  &::-webkit-scrollbar-track {
    background: $scrollbar-track-bg;
  }
}
.App {
  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    h1 {
      color: white;
      text-align: center;
    }
    nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        padding: 0;
        margin: 0;
        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          a {
            padding: 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          button {
            outline: none;
            border: none;
            background: rgb(30, 30, 30);
            border-radius: 5px;
            transition: 100ms ease all;
            cursor: pointer;
            padding: 10px 20px;
            color: white;
            &:hover {
              background: rgb(25, 25, 25);
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@import "./page-connector";
