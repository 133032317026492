.page.edit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  color: white;
  padding-bottom: 10%;
  * {
    img {
      max-width: 100%;
    }
  }
  form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    max-width: 80%;
    width: 50%;
    min-width: 200px;
    padding: 20px;
    button {
        padding: 10px 20px;
        outline: none;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        background: $grey-dark;
        transition: 100ms ease all;
        &[is-delete-button="true"] {
          background: $primary-error-red;
        }
        &:hover {
          background: $primary-darker;
          &[is-delete-button="true"] {
            background: $primary-error-red-darker;
          }
        }
      }
      input,
      textarea {
        width: 100%;
        border-radius: 5px;
        color: #ccc;
        padding: 10px 10px;
        background: $grey-dark;
        resize: vertical;
        outline: 1px solid transparent;
        border: none;
        &:focus,
        &:target,
        &:checked {
          outline: 1px solid #565656;
        }
      }
  }
}
