.page.admin {
  color: white;
  padding-bottom: 10%;
  * {
    img {
      max-width: 100%;
    }
  }
  h2 {
    text-align: center;
  }
  .error-message {
    width: 100%;
    padding: 10px 20px;
    background: $primary-error-red;
    color: white;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .title-wrapper {
      .title {
        text-align: center;
      }
    }
    .global-signout-button {
      margin-bottom: 10px;
    }
    .add-post {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;
        input, textarea, button {
            max-width: 80%;
        }
    }
    button.styled {
      padding: 10px 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      color: white;
      background: $grey-dark;
      transition: 100ms ease all;
      &[is-delete-button="true"] {
        background: $primary-error-red;
      }
      &:hover {
        background: $primary-darker;
        &[is-delete-button="true"] {
          background: $primary-error-red-darker;
        }
      }
    }
    input,
    textarea {
      width: 100%;
      border-radius: 5px;
      color: #ccc;
      padding: 10px 10px;
      background: $grey-dark;
      resize: vertical;
      outline: 1px solid transparent;
      border: none;
      &:focus,
      &:target,
      &:checked {
        outline: 1px solid #565656;
      }
    }
    .sub-set {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #565656;
      }
    }
    .add-post {
      width: 100%;
      margin-top: 10px;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
        background: #565656;
        position: absolute;
      }
    }
    .markdown-editor {
      width: 100%;
    }
    .post {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      outline: 1px solid #565656;
      padding: 10px 20px;
      gap: 10px;
      .global-delete {
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        background: $primary-error-red;
        transition: 100ms ease all;
        &:hover {
          background: $primary-error-red-darker;
        }
      }
    }
  }
}
